import React from "react"
import Layout from "../components/layout"
import Notice from "../components/privacy/Notice"

const privacidad = () => {
  return (
    <Layout>
      <Notice />
    </Layout>
  )
}

export default privacidad
