import React from "react"

const Notice = () => {
  return (
    <section id="aviso" className="notice">
      <div className="content">
        <h1 className="title text-green">Aviso de Privacidad</h1>
        <div>
          <p className="text-green">
            La información dada en este sitio web está dirigido exclusivamente a
            las personas que tienen interés en el mismo por lo que todo lo
            mostrado en la misma es sólo propiedad del desarrollador y/o Privada
            Cumbres y/o Rumah Cumbres y no debe ni puede ser utilizado para
            fines personales. Asimismo la información dada dentro de este sitio
            web se encuentra protegida por la Ley de datos personales por lo que
            la autorización debidamente otorgada sólo será de uso exclusivo del
            desarrollador y/o Privada Cumbres y/o Rumah Cumbres.{" "}
          </p>
          <p className="text-green">
            Los derechos de privacidad y confidencialidad de la información en
            este sitio web no deben perderse por el hecho de haberse trasmitido
            erróneamente o por causas de interferencias en el funcionamiento de
            los sistemas de correo y canales de comunicación. Toda opinión que
            se expresa en este mensaje pertenece a la persona remitente por lo
            que no debe entenderse necesariamente como una opinión, a menos que
            el remitente esté autorizado para hacerlo o expresamente lo diga en
            el mismo mensaje. En consideración a que los mensajes enviados de
            manera electrónica pueden ser interceptados y manipulados, no lo
            hace responsable si los mensajes llegan con demora, incompletos,
            eliminados o con algún programa malicioso denominado como virus
            informático.
          </p>
        </div>
      </div>
    </section>
  )
}

export default Notice
